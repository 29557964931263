
.animated-cometa {
  -webkit-animation: splash 10s linear forwards;
  -moz-animation: splash 10s linear forwards;
  -o-animation: splash 10s linear forwards;
  animation: splash 10s linear forwards;
}

@-webkit-keyframes splash {
  0%   { box-shadow: 0 0 2.5px 2.5px rgba(255, 0, 0, 0.63); }
  30%  { box-shadow: 0 0 5px 5px red; }
  70%  { box-shadow: 0 0 2.5px 2.5px rgba(228, 191, 27, 0.692); }
  100% { box-shadow: 0 0 1px 1px rgba(212, 0, 255, 0.432); }
}
@-moz-keyframes splash {
  0%   { box-shadow: 0 0 2.5px 2.5px rgba(255, 0, 0, 0.63); }
  30%  { box-shadow: 0 0 5px 5px red; }
  70%  { box-shadow: 0 0 2.5px 2.5px rgba(228, 191, 27, 0.692); }
  100% { box-shadow: 0 0 1px 1px rgba(212, 0, 255, 0.432); }
}
@-o-keyframes splash {
  0%   { box-shadow: 0 0 2.5px 2.5px rgba(255, 0, 0, 0.63); }
  30%  { box-shadow: 0 0 5px 5px red; }
  70%  { box-shadow: 0 0 2.5px 2.5px rgba(228, 191, 27, 0.692); }
  100% { box-shadow: 0 0 1px 1px rgba(212, 0, 255, 0.432); }
}
@keyframes splash {
  0%   { box-shadow: 0 0 2.5px 2.5px rgba(255, 0, 0, 0.63); }
  30%  { box-shadow: 0 0 5px 5px red; }
  70%  { box-shadow: 0 0 2.5px 2.5px rgba(228, 191, 27, 0.692); }
  100% { box-shadow: 0 0 1px 1px rgba(212, 0, 255, 0.692); }
}